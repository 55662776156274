import React from 'react'
import ReactRouter from 'react-router-dom'

import { getCookies } from 'libs/common/cookies'

import { isTrialPeriodEnd } from 'utils/seller.utils'

import { useCabinetStores } from '../../hooks/use-cabinet-stores'

import ExpiredTrial from '../../screens/expired-trial/ExpiredTrial'
import VerifyEmail from '../../screens/verify-email/VerifyEmail'

interface GuardRouteProps extends ReactRouter.RouteChildrenProps {
  children: React.ReactNode
}

export const GuardRoute: React.FC<GuardRouteProps> = (props) => {
  const { children, ...restProps } = props
  const { userStore, sellerStore } = useCabinetStores()
  const isEmailConfrimed = userStore.item.confirmed

  const adminAccessToken = getCookies('admin_access_token') as string

  if (!isEmailConfrimed && !(adminAccessToken && !!adminAccessToken.length)) {
    return <VerifyEmail {...restProps} />
  }

  if (isTrialPeriodEnd(sellerStore.item.planId)) {
    return <ExpiredTrial />
  }

  return children
}
