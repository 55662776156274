import React from 'react'
import classNames from 'classnames'

import { EloButton } from '@elo-kit/components/elo-button/EloButton'
import { InfoTooltip } from '@elo-kit/components/info-tooltip/InfoTooltip'
import { useI18n } from '@elo-kit/components/i18n/i18n'
import { EloTooltip } from '@elo-ui/components/elo-tooltip'
import { EloInfoIcon } from '@elo-ui/components/icons/regular'
import { EloButton as EloNewButton } from '@elo-ui/components/elo-button'

import './white-widget.scss'

interface WhiteWidgetProps {
  tooltipTitle?: boolean
  active?: boolean
  selected?: boolean
  noMargin?: boolean
  headerCentered?: boolean
  wrapperClassName?: string
  children?: React.ReactNode
  title?: string
  tooltipId?: string
  disabled?: boolean
  subTitle?: React.ReactNode
  className?: string
  handleCreate?: () => void
  customHandleCreate?: () => void
  btnAction?: () => void
  btnActionText?: string
  btnActionComponentSecond?: React.ReactNode
  headerFlexWrap?: boolean
  fluid?: boolean
  rounded?: boolean
  bodyParams?: Record<string, string>
  titleNoMargin?: boolean
  tooltipIcon?: React.ReactNode
  tooltipClassName?: string
  titleClassName?: string
  btnActionComponent?: React.ReactNode
  btnActionSecond?: () => void
  btnActionAttributes?: Record<string, any>
  btnActionSecondAttributes?: Record<string, any>
  widgetIcon?: React.ReactNode
  useNewTooltip?: boolean
  withBorderFrame?: boolean
  baseLayer?: boolean
  titleRightSection?: React.ReactNode
  dataTestId?: string
}

const WhiteWidget = (props: WhiteWidgetProps) => {
  const {
    title = '',
    className,
    tooltipId,
    tooltipTitle = false,
    tooltipClassName,
    children = false,
    subTitle = false,
    disabled = false,
    active = true,
    selected = false,
    noMargin = false,
    wrapperClassName,
    headerCentered = false,
    headerFlexWrap,
    fluid,
    rounded = false,
    btnAction,
    btnActionText,
    handleCreate,
    customHandleCreate,
    tooltipIcon,
    titleNoMargin,
    titleClassName,
    titleRightSection,
    btnActionComponent,
    btnActionSecond,
    btnActionAttributes,
    btnActionComponentSecond,
    btnActionSecondAttributes,
    widgetIcon,
    useNewTooltip,
    withBorderFrame,
    baseLayer,
    dataTestId,
  } = props
  const I18n = useI18n()

  const whiteWidgetClasses = classNames(
    'white-widget white-widget--not-last',
    {
      'white-widget--disabled': disabled,
      'white-widget--fluid': fluid,
      'white-widget--rounded': rounded,
      'white-widget--selected': selected,
      'white-widget--with-border': withBorderFrame,
      'white-widget--base-layer': baseLayer,
    },
    wrapperClassName
  )

  const whiteWidgetContentClasses = classNames('white-widget__content', {
    'white-widget__content--with-icon': widgetIcon != null,
  })

  const widgetHeaderClasses = classNames('white-widget__header', {
    'white-widget__header--centered': headerCentered,
    'white-widget__header--flex-wrap': headerFlexWrap,
  })

  const widgetTitleClasses = classNames(
    'white-widget__title',
    {
      'white-widget__title--margin': active && !noMargin,
      'white-widget__title--no-margin': titleNoMargin,
    },
    titleClassName
  )

  const showButtonsWrapper = (btnActionSecond && btnActionComponentSecond) || (btnAction && btnActionComponent)
  const buttonActionClasses = classNames('white-widget__first-button', {
    'elo-btn--disabled': btnActionAttributes?.disabled,
  })
  const buttonActionSecondClasses = classNames('white-widget__second-button', {
    'elo-btn--disabled': btnActionSecondAttributes?.disabled,
  })

  const tooltipTitles = {
    product_limit: I18n.t('react.cabinet.help_icon.product_limit.title'),
    product_name: I18n.t('react.cabinet.help_icon.product_name.title'),
    product_cover: I18n.t('react.cabinet.help_icon.product_cover.title'),
  }

  const tooltipContent = {
    product_limit: I18n.t('react.cabinet.help_icon.product_limit.content'),
    additional_fees_template: I18n.t('react.cabinet.help_icon.additional_fees_template.content'),
    custom_checkout: I18n.t('react.cabinet.help_icon.custom_checkout.content'),
    opt_in: I18n.t('react.cabinet.help_icon.opt_in.content'),
    protection_url: I18n.t('react.cabinet.help_icon.protection_url.content'),
    community_settings: I18n.t('react.cabinet.help_icon.community_settings.content'),
    product_name: I18n.t('react.cabinet.help_icon.product_name.content'),
    product_cover: I18n.t('react.cabinet.help_icon.product_cover.content'),
    custom_vat: I18n.t('react.cabinet.help_icon.custom_vat.content'),
    shop_mobile_app_general_settings: I18n.t('react.cabinet.help_icon.shop_mobile_app_general_settings.content'),
    cancellation_short_description: I18n.t('react.cabinet.help_icon.cancellation_short_description.content'),
    embeddable_items_redirect_to: I18n.t('react.cabinet.help_icon.embeddable_items_redirect_to.content'),
    embeddable_items_connect_to: I18n.t('react.cabinet.help_icon.embeddable_items_connect_to.content'),
    custom_css_block: I18n.t('react.cabinet.help_icon.custom_css_block.content'),
    customize_checkout: I18n.t('react.cabinet.help_icon.customize_checkout.content'),
    membership_mobile_app_general_settings: I18n.t(
      'react.cabinet.help_icon.membership_mobile_app_general_settings.content'
    ),
    affiliate_landing_pages: I18n.t('react.cabinet.help_icon.affiliate_landing_pages.content'),
  }

  return (
    <div className={whiteWidgetClasses} {...(dataTestId ? { 'data-test-id': dataTestId } : {})}>
      {widgetIcon && <div className='white-widget__icon'>{widgetIcon}</div>}
      <div className={whiteWidgetContentClasses}>
        {title && (
          <div className={widgetHeaderClasses}>
            <div className={widgetTitleClasses}>
              {title}
              {tooltipIcon}
              {tooltipId && (
                <>
                  {useNewTooltip ? (
                    <EloTooltip
                      content={
                        <div
                          dangerouslySetInnerHTML={{
                            __html: tooltipContent[tooltipId],
                          }}
                        />
                      }
                    >
                      <EloInfoIcon />
                    </EloTooltip>
                  ) : (
                    <InfoTooltip
                      className={tooltipClassName}
                      id={`${tooltipId}_popover`}
                      title={tooltipTitle ? tooltipTitles[tooltipId] : ''}
                      body={tooltipContent[tooltipId]}
                    />
                  )}
                </>
              )}
            </div>
            {subTitle && <div className='white-widget__sub-title'>{subTitle}</div>}

            {handleCreate && (
              <EloButton onClick={handleCreate} orange>
                {I18n.t('react.shared.button.create')}
              </EloButton>
            )}

            {customHandleCreate && (
              <EloNewButton
                className='custom-create-button'
                variant='secondary'
                size='medium'
                onClick={customHandleCreate}
              >
                {I18n.t('react.shared.button.create_landing_page')}
              </EloNewButton>
            )}

            {btnAction && btnActionText && (
              <EloButton onClick={btnAction} outlined>
                {btnActionText}
              </EloButton>
            )}

            {titleRightSection && titleRightSection}

            {showButtonsWrapper && (
              <div className='white-widget__buttons-wrapper'>
                {btnActionSecond && btnActionComponentSecond && (
                  <EloButton
                    className={buttonActionSecondClasses}
                    onClick={btnActionSecond}
                    outlined
                    {...btnActionSecondAttributes}
                  >
                    {btnActionComponentSecond}
                  </EloButton>
                )}

                {btnAction && btnActionComponent && (
                  <EloButton className={buttonActionClasses} onClick={btnAction} outlined {...btnActionAttributes}>
                    {btnActionComponent}
                  </EloButton>
                )}
              </div>
            )}
          </div>
        )}
        {active && <div className={className}>{children}</div>}
      </div>
    </div>
  )
}

WhiteWidget.displayName = 'WhiteWidget'

export default WhiteWidget
