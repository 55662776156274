import React, { useEffect } from 'react'

import { TopBar } from 'ui/top-bar/TopBar'
import WhiteWidget from 'ui/white-widget/WhiteWidget'
import { EloButton } from '@elo-ui/components/elo-button'
import { useI18n } from '@elo-kit/components'

import { addToDataLayer } from 'utils/GTMDataLayer.utils'

import { CONTACT_US_LINK, UPGRADE_PLAN_LINK } from 'constants/helpCenter.constants'
import { SELLER_PAGE_VIEW_EVENT, SELLER_BUTTON_CLICK_EVENT } from 'constants/GTMDataLayer.constants'

import ExpiredTrialSvg from '../../assets/expiredTrial.svg'

import './expired_trial.scss'

const ExpiredTrial = () => {
  useEffect(() => {
    addToDataLayer({
      ...SELLER_PAGE_VIEW_EVENT,
      object: 'overview',
      event_name: 'overview_viewTrialExpired',
    })
  }, [])

  const I18n = useI18n()

  const handleSelectPlan = () => {
    addToDataLayer({
      ...SELLER_BUTTON_CLICK_EVENT,
      object: 'overview',
      event_name: 'overview_upgradeTrialExpired',
    })

    window.open(UPGRADE_PLAN_LINK, '_blank')
  }

  return (
    <>
      <TopBar />
      <div className='content'>
        <WhiteWidget className='expired-trial'>
          <div className='expired-trial__title'>{I18n.t('react.cabinet.expired_trial.title')}</div>
          <div className='expired-trial__message'>{I18n.t('react.cabinet.expired_trial.message')}</div>
          <div className='expired-trial__buttons'>
            <EloButton onClick={handleSelectPlan} variant='highlight'>
              {I18n.t('react.shared.button.select_a_plan')}
            </EloButton>
            <EloButton onClick={() => window.open(CONTACT_US_LINK, '_blank')} variant='secondary'>
              {I18n.t('react.shared.help_center.support.button')}
            </EloButton>
          </div>
          <img className='expired-trial__image' src={ExpiredTrialSvg} alt='expired_trial' />
        </WhiteWidget>
      </div>
    </>
  )
}

export default ExpiredTrial
