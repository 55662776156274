import React from 'react'
import ReactRouter from 'react-router-dom'

import { TopBar } from 'ui/top-bar/TopBar'
import WhiteWidget from 'ui/white-widget/WhiteWidget'
import { EloButton } from '@elo-ui/components/elo-button'
import { useI18n } from '@elo-kit/components/i18n/i18n'

import { APP_USERS_CONFIRMATION_NEW_PATH } from 'constants/routes/app.constants'

import VerifyEmailSvg from '../../assets/verifyEmail.svg'

import { useCabinetStores } from '../../hooks/use-cabinet-stores'

import './verify-email.scss'

export const TEST_IDS = {
  verifyEmailTitle: 'verify-email-title',
}

const VerifyEmail: React.FC<ReactRouter.RouteChildrenProps> = (props) => {
  const { history } = props
  const { userStore } = useCabinetStores()
  const I18n = useI18n()

  const encodedEmail = encodeURIComponent(userStore.item.email)
  const verifyEmailPath = `${APP_USERS_CONFIRMATION_NEW_PATH}?email=${encodedEmail}`

  return (
    <>
      <TopBar />
      <div className='content'>
        <WhiteWidget className='verify-email'>
          <div className='verify-email__title' data-testid={TEST_IDS.verifyEmailTitle}>
            {I18n.t('react.cabinet.verify_email.title')}
          </div>
          <div className='verify-email__message'>{I18n.t('react.cabinet.verify_email.message')}</div>
          <div className='verify-email__buttons'>
            <EloButton onClick={() => window.open(verifyEmailPath, '_blank')} variant='highlight'>
              {I18n.t('react.shared.button.resend_email')}
            </EloButton>
            <EloButton
              onClick={() => history.push('/cabinet/profile/edit?change_profile_modal_open=true')}
              variant='secondary'
            >
              {I18n.t('react.shared.change_email')}
            </EloButton>
          </div>
          <img className='verify-email__image' src={VerifyEmailSvg} alt='verify-email' />
        </WhiteWidget>
      </div>
    </>
  )
}

export default VerifyEmail
